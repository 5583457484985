import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../views/Home/HomePage.vue'
import AboutPage from '../views/About/AboutPage.vue'
import SubscriptionsPage from '../views/Subscriptions/SubscriptionsPage.vue'
import SubscriptionPage from '../views/Subscriptions/Subscription.vue'
import ContactPage from '../views/Contact/ContactPage.vue'
import ServicesPage from '../views/Services/ServicesPage.vue'


const routes = [
  {
    path: '/',
    component: HomePage
  },
  {
    path: '/about',
    component: AboutPage
  },
  {
    path: '/Subscriptions',
    component: SubscriptionsPage
  },
  {
    path: '/Subscriptions/:id',
    component: SubscriptionPage
  },
  {
    path: '/Contact',
    component: ContactPage
  },
  {
    path: '/Services/:id',
    component: ServicesPage
  },

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
    scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
})

router.afterEach((from , to )=>{
  setTimeout(() => {
    window.scrollTo(0, 0);
}, 100);
})
export default router
