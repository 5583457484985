<template>

    <!-- Modal -->
<div class="modal modal-fullscreen fade" style="z-index:50" id="forget" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl" style="z-index:50">
    <div class="modal-content" >
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="cs-login">
          <div class="cs-login_left">
            <img src="assets/img/about_img_1.png" alt="Login Thumb">
          </div>
          <div class="cs-login_right">
            <form @submit.prevent="forgetPassword()" class="cs-login_form">
              <h2>{{$t("login.forget_section.title")}}</h2>
              <div class="cs-height_30 cs-height_lg_30"></div>
              <input v-model="email" type="text" class="cs-form_field cs-border_color" :placeholder="$t('login.email')">
              <div class="cs-height_20 cs-height_lg_20"></div>
              <button class="cs-btn  cs-size_md w-100"><span>{{$t("login.forget_section.button_text")}}</span></button>
              <span class="forget_btn"  data-bs-toggle="modal" data-bs-target="#verfiy" style="display : none">{{$t("login.forget_section.button_text")}}</span>
              <div class="cs-height_20 cs-height_lg_20"></div>
              <p class="cs-m0">
                {{$t("login.forget_section.have_account")}}
                <span class="cs-text_btn cs-modal_btn" data-modal="register"><span>{{$t("login.register")}}</span></span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      email : ""
    }
  },
  methods:{
   async forgetPassword(){
      if(!this.email){
        this.$swal.fire({
        title: `error`,
        text: ` Please Add Email `,
        icon: `error`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
     
      }

        const data = {
          email : this.email
        }
        console.log({data})
        const res = await this.http.post("users/forget-password/email" , data)
        console.log(res);
        if(!res?.status) {
          console.log("error")
              this.$swal.fire({
        title: `error`,
        text: ` Your Email Does not exist `,
        icon: `error`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
        }else{
          localStorage.setItem("resetEmail" , this.email)
          let btn =  document.querySelector(".forget_btn")
          btn.click()
   
        }
       
  }

  }}
</script>

<style>

</style>