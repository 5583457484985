    // import { userService } from "../../helpers/authservice/user.service";
    // import router from "../../router/index";
    // import axios from "../../services/axios";

const user = localStorage["user"] && JSON.parse(localStorage["user"]);
export const state = {
  imgUrl: "https://smartpos.smart-node.net/",
  status: { loggeduser: user && true },
  user,
  permissions: null,
  selected_company: "",
  selected_company_id: null,
  currentUser: JSON.parse(localStorage.getItem("pos_current_user")),
  companies: [],
  allCompanies: [],
};

