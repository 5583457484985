<template>
  <layout >
    <contact />
  </layout>
</template>

<script>
import Layout from '@/components/mainLayout/Layout.vue'
import Contact from './Contact.vue'
export default {
  components: { Layout, Contact },

}
</script>

<style>

</style>