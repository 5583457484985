<template>
  <div>


    <main-header />

    <slot />

    <main-footer />
  </div>
</template>
<script>
import MainFooter from './MainFooter.vue';
import MainHeader from './MainHeader.vue';
export default {
    components : {
      MainHeader,
      MainFooter,
    }
}
</script>

<style>

</style>