<template>
  
  <!-- Start Contact Section -->
  <section class="cs-gradient_bg_1" id="contact">
    <div class="cs-height_95 cs-height_lg_70"></div>
    <div class="container">
      <div class="row">
        <div class="col-xl-5 col-lg-8">
          <div class="cs-seciton_heading cs-style1">
            <div class="cs-section_subtitle wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">{{ $t("contact.sub_title") }}</div>
            <div class="cs-height_10 cs-height_lg_10"></div>
            <h3 class="cs-section_title">{{ $t("contact.title") }}</h3>
          </div>
          <div class="cs-height_20 cs-height_lg_20"></div>
          <p>
            {{ $t("contact.descr") }}
          </p>
          <div class="cs-height_15 cs-height_lg_15"></div>
          <div class="cs-iconbox cs-style3">
            <div class="cs-iconbox_icon">
              <img src="assets/img/icons/contact_icon_1.svg" alt="Icon">
            </div>
            <div class="cs-iconbox_right">
              <h2 class="cs-iconbox_title">{{ $t("contact.address") }}</h2>
              <div class="cs-iconbox_subtitle">{{  $t("contact.address_details")  }}</div>
            </div>
          </div>
          <div class="cs-height_30 cs-height_lg_30"></div>
          <div class="cs-iconbox cs-style3">
            <div class="cs-iconbox_icon">
              <img src="assets/img/icons/contact_icon_2.svg" alt="Icon">
            </div>
            <div class="cs-iconbox_right">
              <h2 class="cs-iconbox_title">{{ $t("contact.number") }}</h2>
              <div class="cs-iconbox_subtitle">{{ $t("contact.number_details") }}</div>
            </div>
          </div>
          <div class="cs-height_30 cs-height_lg_30"></div>
          <div class="cs-iconbox cs-style3">
            <div class="cs-iconbox_icon">
              <img src="assets/img/icons/contact_icon_3.svg" alt="Icon">
            </div>
            <div class="cs-iconbox_right">
              <h2 class="cs-iconbox_title">{{ $t("contact.email") }}</h2>
              <div class="cs-iconbox_subtitle">{{ $t("contact.email_details") }}</div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 offset-xl-1">
          <div class="cs-height_40 cs-height_lg_40"></div>
          <form class="cs-contact_form">
            <h2 class="cs-contact_form_title">{{ $t("contact.alert") }}</h2>
            <div class="row">
              <div class="col-lg-6">
                <input type="text" class="cs-form_field"  :placeholder="$t('contact.name')">
                <div class="cs-height_25 cs-height_lg_25"></div>
              </div>
              <div class="col-lg-6">
                <input type="text" class="cs-form_field"  :placeholder="$t('contact.email')">
                <div class="cs-height_25 cs-height_lg_25"></div>
              </div>
              <div class="col-lg-12">
                <input type="text" class="cs-form_field"  :placeholder="$t('contact.phone')">
                <div class="cs-height_25 cs-height_lg_25"></div>
              </div>
              <div class="col-lg-12">
                <textarea cols="30" rows="5" class="cs-form_field" :placeholder="$t('contact.message')"></textarea>
                <div class="cs-height_25 cs-height_lg_25"></div>
              </div>
              <div class="col-lg-12">
                <button class="cs-btn cs-size_md"><span>{{ $t("contact.button_text") }}</span></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="cs-height_95 cs-height_lg_70"></div>
  </section>
  <!-- End Contact Section -->

</template>

<script>
export default {

}
</script>

<style>

</style>