export default [
    {},
    { about_section: {
    title: "Point Of sales for Pharmacy",
    img : "https://ohiocapitaljournal.com/wp-content/uploads/2023/03/CVS-pharmacy-inside-Lynne-Terry-scaled.jpg",
    description: "We are a leading provider of pharmacy POS systems that are designed to help pharmacies of all sizes run more efficiently and profitably. Our systems are easy to use and packed with features that can help you improve customer service, manage inventory, and track sales. We understand that the pharmacy industry is constantly changing, and we are committed to providing our customers with the most up-to-date and innovative POS solutions available. Our systems are compliant with all applicable regulations, and we offer a variety of support options to help you get the most out of your investment.",
    features: [

        "Customer relationship management (CRM): Track customer purchase history, preferences, and feedback. Use CRM data to create targeted marketing campaigns and promotions."
        ,

        "Inventory management: Manage items and quantities in inventory, track inventory movement and determine safety levels, order the necessary quantities from suppliers, and generate inventory reports."
        ,

        "Prescription management: Manage customer prescriptions, including filling prescriptions, tracking prescription status, and managing refills."
        ,

        "Point-of-sale (POS) functionality: Process sales, accept payments, and print receipts."
        ,
        "Delivery boy management: Assign delivery orders to delivery boys, track the status of orders, and generate delivery reports."

    ]
},
features: [
    {
        "title": "real-time sales reports",
        "icon": "",
        "description": " are a valuable tool for any business that uses a point of sale (POS) system. They provide insights into your business's performance on a moment-by-moment basis, which can help you make informed decisions about your inventory, staffing, and marketing strategies."
    },
    {
        "title": "Track inventory levels and generate inventory reports",
        "icon": "",
        "description": " are a valuable tool for any business that uses a point of sale (POS) system. They provide insights into your business's performance on a moment-by-moment basis, which can help you make informed decisions about your inventory, staffing, and marketing strategies."
    },
    {
        "title": "Track inventory levels and generate inventory reports",
        "icon": "",
        "description": " are a valuable tool for any business that uses a point of sale (POS) system. They provide insights into your business's performance on a moment-by-moment basis, which can help you make informed decisions about your inventory, staffing, and marketing strategies."
    },
    {
        "title": "Track inventory levels and generate inventory reports",
        "icon": "",
        "description": " are a valuable tool for any business that uses a point of sale (POS) system. They provide insights into your business's performance on a moment-by-moment basis, which can help you make informed decisions about your inventory, staffing, and marketing strategies."
    },
    {
        "title": "Track inventory levels and generate inventory reports",
        "icon": "",
        "description": " are a valuable tool for any business that uses a point of sale (POS) system. They provide insights into your business's performance on a moment-by-moment basis, which can help you make informed decisions about your inventory, staffing, and marketing strategies."
    }
],
services: [
    {
        "id" : "1",
        "title": "track inventory levels in real time",
        "description": "A pharmacy POS system can help you track inventory levels in real time. This means that you can always see how much of each product you have on hand, without having to manually count your inventory. Pharmacy POS systems can also help you generate inventory reports, such as:",
        "features": [
            "Stock status report: This report shows you the current stock level of each product.",
            "Reorder point report: This report identifies the products that are running low and need to be reordered.",
            "Inventory movement report: This report shows you the movement of products into and out of your inventory over a period of time.",
            "Inventory valuation report: This report shows you the value of your inventory at a given point in time"
        ]
    },
    {
        "id" : "2",

        "title": "track inventory levels in real time",
        "description": "A pharmacy POS system can help you track inventory levels in real time. This means that you can always see how much of each product you have on hand, without having to manually count your inventory. Pharmacy POS systems can also help you generate inventory reports, such as:",
        "features": [
            "Stock status report: This report shows you the current stock level of each product.",
            "Reorder point report: This report identifies the products that are running low and need to be reordered.",
            "Inventory movement report: This report shows you the movement of products into and out of your inventory over a period of time.",
            "Inventory valuation report: This report shows you the value of your inventory at a given point in time"
        ]
    },
    {
        "id" : "3",
        "title": "track inventory levels in real time",
        "description": "A pharmacy POS system can help you track inventory levels in real time. This means that you can always see how much of each product you have on hand, without having to manually count your inventory. Pharmacy POS systems can also help you generate inventory reports, such as:",
        "features": [
            "Stock status report: This report shows you the current stock level of each product.",
            "Reorder point report: This report identifies the products that are running low and need to be reordered.",
            "Inventory movement report: This report shows you the movement of products into and out of your inventory over a period of time.",
            "Inventory valuation report: This report shows you the value of your inventory at a given point in time"
        ]
    }

]

},


// second

{
    "about_section": {
      "title": "Point of Sale for Stores",
      "img" : "https://www.realsimple.com/thmb/aJDUpqoFWZXJdFvU5XPkjjmAV7c=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/impulse-buying-2000-d569eacebb0c48fa8766965d0352151c.jpg",
      "description": "We are a leading provider of point of sale (POS) systems that are designed to help stores of all sizes run more efficiently and profitably. Our systems are easy to use and packed with features that can help you improve customer service, manage inventory, track sales, and more. We understand that the retail industry is constantly changing, and we are committed to providing our customers with the most up-to-date and innovative POS solutions available. Our systems are compliant with all applicable regulations, and we offer a variety of support options to help you get the most out of your investment.",
      "features": [
        "Customer relationship management (CRM): Track customer purchase history, preferences, and feedback. Use CRM data to create targeted marketing campaigns and promotions.",
        "Inventory management: Manage items and quantities in inventory, track inventory movement and determine safety levels, order the necessary quantities from suppliers, and generate inventory reports.",
        "Point-of-sale (POS) functionality: Process sales, accept payments, and print receipts.",
        "Employee management: Track employee hours, performance, and commissions.",
        "Reporting and analytics: Generate comprehensive reports on all aspects of your business, including sales, inventory, employee performance, and customer behavior."
      ]
    },
    "features": [
      {
        "title": "Real-time sales reports",
        "icon": "",
        "description": "Real-time sales reports are a valuable tool for any business that uses a point of sale (POS) system. They provide insights into your business's performance on a moment-by-moment basis, which can help you make informed decisions about your inventory, staffing, and marketing strategies."
      },
      {
        "title": "Track inventory levels and generate inventory reports",
        "icon": "",
        "description": "Tracking inventory levels and generating inventory reports are essential for any store. It allows you to ensure that you have enough of the right products on hand to meet the needs of your customers, while also avoiding overstocking and waste."
      }
    ],
    "services": [
      {
        "title": "Track inventory levels in real time",
        "description": "A store POS system can help you track inventory levels in real time. This means that you can always see how much of each product you have on hand, without having to manually count your inventory. Store POS systems can also help you generate inventory reports, such as:",
        "features": [
          "Stock status report: This report shows you the current stock level of each product.",
          "Reorder point report: This report identifies the products that are running low and need to be reordered.",
          "Inventory movement report: This report shows you the movement of products into and out of your inventory over a period of time.",
          "Inventory valuation report: This report shows you the value of your inventory at a given point in time."
        ]
      }
    ]
  },

  {
    "about_section": {
      "title": "Point of Sale for Restaurants",
      "img": "https://multitouchtables.net/wp-content/uploads/2018/11/pos-slide.jpg",
      "description": "We are a leading provider of point of sale (POS) systems that are designed to help restaurants of all sizes run more efficiently and profitably. Our systems are easy to use and packed with features that can help you improve customer service, manage inventory, track sales, and more. We understand that the restaurant industry is constantly changing, and we are committed to providing our customers with the most up-to-date and innovative POS solutions available. Our systems are compliant with all applicable regulations, and we offer a variety of support options to help you get the most out of your investment.",
      "features": [
        "Customer relationship management (CRM): Track customer purchase history, preferences, and feedback. Use CRM data to create targeted marketing campaigns and promotions.",
        "Inventory management: Manage items and quantities in inventory, track inventory movement and determine safety levels, order the necessary quantities from suppliers, and generate inventory reports.",
        "Point-of-sale (POS) functionality: Process sales, accept payments, and print receipts.",
        "Employee management: Track employee hours, performance, and commissions.",
        "Reporting and analytics: Generate comprehensive reports on all aspects of your business, including sales, inventory, employee performance, and customer behavior.",
        "Floor management: Manage floorplans, tables, and reservations.",
        "Table management: Track table status, orders, and bills."
      ]
    },
    "features": [
      {
        "title": "Real-time sales reports",
        "icon": "",
        "description": "Real-time sales reports are a valuable tool for any business that uses a point of sale (POS) system. They provide insights into your business's performance on a moment-by-moment basis, which can help you make informed decisions about your inventory, staffing, and marketing strategies."
      },
      {
        "title": "Track inventory levels and generate inventory reports",
        "icon": "",
        "description": "Tracking inventory levels and generating inventory reports are essential for any restaurant. It allows you to ensure that you have enough of the right ingredients on hand to meet the needs of your customers, while also avoiding overstocking and waste."
      },
      {
        "title": "Floor management",
        "icon": "",
        "description": "Floor management features allow you to manage your restaurant's floorplan, tables, and reservations. This can help you to optimize your seating capacity and improve customer service."
      },
      {
        "title": "Table management",
        "icon": "",
        "description": "Table management features allow you to track the status of each table in your restaurant, including the orders and bills for each table. This can help you to improve your table turnover and reduce wait times for customers."
      }
    ],
    "services": [
      {
        "title": "Track inventory levels in real time",
        "description": "A restaurant POS system can help you track inventory levels in real time. This means that you can always see how much of each ingredient you have on hand, without having to manually count your inventory. Restaurant POS systems can also help you generate inventory reports, such as:",
        "features": [
          "Stock status report: This report shows you the current stock level of each ingredient.",
          "Reorder point report: This report identifies the ingredients that are running low and need to be reordered.",
          "Inventory movement report: This report shows you the movement of ingredients into and out of your inventory over a period of time.",
          "Inventory valuation report: This report shows you the value of your inventory at a given point in time."
        ]
      }
    ]
  }
]