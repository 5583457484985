import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./i18n.js";
import FlagIcon from "vue-flag-icon";

import "animate.css";

import BootstrapVue3 from "bootstrap-vue-3";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "./assets/style/style.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import $e from "./services/$e";

import http from "./services/http";

createApp(App)
  .use(store)
  .use(router)
  .use(FlagIcon)
  .use(BootstrapVue3)
  .use(i18n)
  .use(VueSweetalert2)
  .use($e)
  .use(http)
  .mount("#app");
