<template>
        <!-- Start All Feature -->
        <section class="cs-bg" data-src="assets/img/feature_bg.svg">
            <div id="feature">
              <div class="cs-height_95 cs-height_lg_70"></div>
              <div class="container">
                <div class="cs-seciton_heading cs-style1 text-center">
                  <div class="cs-section_subtitle wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">{{$t("about.about_featuers.sub_title")}}</div>
                  <div class="cs-height_10 cs-height_lg_10"></div>
                  <h3 class="cs-section_title">{{$t("about.about_featuers.title")}}</h3>
                </div>
                <div class="cs-height_50 cs-height_lg_40"></div>
                <div class="row">
                  <div class="col-xl-4 col-md-6 my-2">
                    <div class="cs-iconbox cs-style1 cs-type1">
                      <div class="cs-iconbox_icon cs-center">
                        <img src="assets/img/icons/icon_box_5.svg" alt="Icon">               
                      </div>
                      <div class="cs-iconbox_in">
                        <h3 class="cs-iconbox_title">{{$t("about.about_featuers.name1")}}</h3>
                        <div class="cs-iconbox_subtitle">{{$t("about.about_featuers.descr1")}}</div>
                      </div>
                    </div>
                    <div class="cs-height_25 cs-height_lg_25"></div>
                  </div>
                  <div class="col-xl-4 col-md-6 my-2">
                    <div class="cs-iconbox cs-style1 cs-type1">
                      <div class="cs-iconbox_icon cs-center">
                        <img src="assets/img/icons/icon_box_6.svg" alt="Icon">
                      </div>
                      <div class="cs-iconbox_in">
                        <h3 class="cs-iconbox_title">{{$t("about.about_featuers.name2")}}</h3>
                        <div class="cs-iconbox_subtitle">{{$t("about.about_featuers.descr2")}}</div>
                      </div>
                    </div>
                    <div class="cs-height_25 cs-height_lg_25"></div>
                  </div>
                  <div class="col-xl-4 col-md-6 my-2">
                    <div class="cs-iconbox cs-style1 cs-type1">
                      <div class="cs-iconbox_icon cs-center">
                        <img src="assets/img/icons/icon_box_7.svg" alt="Icon">                          
                      </div>
                      <div class="cs-iconbox_in">
                        <h3 class="cs-iconbox_title">{{$t("about.about_featuers.name3")}}</h3>
                        <div class="cs-iconbox_subtitle">{{$t("about.about_featuers.descr3")}}</div>
                      </div>
                    </div>
                    <div class="cs-height_25 cs-height_lg_25"></div>
                  </div>
             
    
                  <div class="col-xl-4 col-md-6 my-2">
                    <div class="cs-iconbox cs-style1 cs-type1">
                      <div class="cs-iconbox_icon cs-center">
                        <img src="assets/img/icons/icon_box_7.svg" alt="Icon">                          
                      </div>
                      <div class="cs-iconbox_in">
                        <h3 class="cs-iconbox_title">{{$t("about.about_featuers.name4")}}</h3>
                        <div class="cs-iconbox_subtitle">{{$t("about.about_featuers.descr4")}}</div>
                      </div>
                    </div>
                    <div class="cs-height_25 cs-height_lg_25"></div>
                  </div>
    
                  <div class="col-xl-4 col-md-6 my-2">
                    <div class="cs-iconbox cs-style1 cs-type1">
                      <div class="cs-iconbox_icon cs-center">
                        <img src="assets/img/icons/icon_box_7.svg" alt="Icon">                          
                      </div>
                      <div class="cs-iconbox_in">
                        <h3 class="cs-iconbox_title">{{$t("about.about_featuers.name5")}}</h3>
                        <div class="cs-iconbox_subtitle">{{$t("about.about_featuers.descr5")}}</div>
                      </div>
                    </div>
                    <div class="cs-height_25 cs-height_lg_25"></div>
                  </div>
    
                  <div class="col-xl-4 col-md-6 my-2">
                    <div class="cs-iconbox cs-style1 cs-type1">
                      <div class="cs-iconbox_icon cs-center">
                        <img src="assets/img/icons/icon_box_7.svg" alt="Icon">                          
                      </div>
                      <div class="cs-iconbox_in">
                        <h3 class="cs-iconbox_title">{{$t("about.about_featuers.name6")}}</h3>
                        <div class="cs-iconbox_subtitle">{{$t("about.about_featuers.descr6")}}</div>
                      </div>
                    </div>
                    <div class="cs-height_25 cs-height_lg_25"></div>
                  </div>
    
                  <div class="col-xl-4 col-md-6 my-2">
                    <div class="cs-iconbox cs-style1 cs-type1">
                      <div class="cs-iconbox_icon cs-center">
                        <img src="assets/img/icons/icon_box_7.svg" alt="Icon">                          
                      </div>
                      <div class="cs-iconbox_in">
                        <h3 class="cs-iconbox_title">{{$t("about.about_featuers.name7")}}</h3>
                        <div class="cs-iconbox_subtitle">{{$t("about.about_featuers.descr7")}}</div>
                      </div>
                    </div>
                    <div class="cs-height_25 cs-height_lg_25"></div>
                  </div>
    
                  <div class="col-xl-4 col-md-6 my-2">
                    <div class="cs-iconbox cs-style1 cs-type1">
                      <div class="cs-iconbox_icon cs-center">
                        <img src="assets/img/icons/icon_box_7.svg" alt="Icon">                          
                      </div>
                      <div class="cs-iconbox_in">
                        <h3 class="cs-iconbox_title">{{$t("about.about_featuers.name8")}}</h3>
                        <div class="cs-iconbox_subtitle">{{$t("about.about_featuers.descr8")}}</div>
                      </div>
                    </div>
                    <div class="cs-height_25 cs-height_lg_25"></div>
                  </div>
    
                  <div class="col-xl-4 col-md-6 my-2">
                    <div class="cs-iconbox cs-style1 cs-type1">
                      <div class="cs-iconbox_icon cs-center">
                        <img src="assets/img/icons/icon_box_7.svg" alt="Icon">                          
                      </div>
                      <div class="cs-iconbox_in">
                        <h3 class="cs-iconbox_title">{{$t("about.about_featuers.name9")}}</h3>
                        <div class="cs-iconbox_subtitle">{{$t("about.about_featuers.descr9")}}</div>
                      </div>
                    </div>
                    <div class="cs-height_25 cs-height_lg_25"></div>
                  </div>
    
                  <div class="col-xl-4 col-md-6 my-2">
                    <div class="cs-iconbox cs-style1 cs-type1">
                      <div class="cs-iconbox_icon cs-center">
                        <img src="assets/img/icons/icon_box_7.svg" alt="Icon">                          
                      </div>
                      <div class="cs-iconbox_in">
                        <h3 class="cs-iconbox_title">{{$t("about.about_featuers.name10")}}</h3>
                        <div class="cs-iconbox_subtitle">{{$t("about.about_featuers.descr10")}}</div>
                      </div>
                    </div>
                    <div class="cs-height_25 cs-height_lg_25"></div>
                  </div>
    
                  <div class="col-xl-4 col-md-6 my-2">
                    <div class="cs-iconbox cs-style1 cs-type1">
                      <div class="cs-iconbox_icon cs-center">
                        <img src="assets/img/icons/icon_box_7.svg" alt="Icon">                          
                      </div>
                      <div class="cs-iconbox_in">
                        <h3 class="cs-iconbox_title">{{$t("about.about_featuers.name11")}}</h3>
                        <div class="cs-iconbox_subtitle">{{$t("about.about_featuers.descr11")}}</div>
                      </div>
                    </div>
                    <div class="cs-height_25 cs-height_lg_25"></div>
                  </div>
    
                  <div class="col-xl-4 col-md-6 my-2">
                    <div class="cs-iconbox cs-style1 cs-type1">
                      <div class="cs-iconbox_icon cs-center">
                        <img src="assets/img/icons/icon_box_7.svg" alt="Icon">                          
                      </div>
                      <div class="cs-iconbox_in">
                        <h3 class="cs-iconbox_title">{{$t("about.about_featuers.name12")}}</h3>
                        <div class="cs-iconbox_subtitle">{{$t("about.about_featuers.descr12")}}</div>
                      </div>
                    </div>
                    <div class="cs-height_25 cs-height_lg_25"></div>
                  </div>
    
                  
                  
                </div>
              </div>
              <div class="cs-height_75 cs-height_lg_45"></div>
            </div>
          </section>
          <!-- End All Feature -->
</template>

<script>
export default {

}
</script>

<style>

</style>