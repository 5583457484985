<template>
  <router-view />
</template>

<script>
export default {
  created() {
    const current_lang = localStorage.getItem("pos_lang");
    let body = document.querySelector("body")

    if (current_lang == "en") {
      body.classList.add("en");
      body.classList.remove("ar");
    } else {
      body.classList.add("ar");
      body.classList.remove("en");
    }
  },
};
</script>
