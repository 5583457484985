<template>
    <layout >
      <hero />
      <feature />
      <about />
      <fun />
      <Subscriptions />
      <testimonials />
      <client />
    </layout>
  </template>
  
  <script>
  // @ is an alias to /src
  import Layout from '@/components/mainLayout/Layout.vue';
import Client from './components/Client.vue';
import Testimonials from './components/Testimonials.vue';
import About from '../About/About.vue';
import Feature from './components/Feature.vue';
import Hero from './components/Hero.vue';
import Subscriptions from '../Subscriptions/Subscriptions.vue';
import Fun from './components/Fun.vue';
  export default {
    name: 'HomeView',
    components: {
        Layout,
        Client,
        Testimonials,
        About,
        Feature,
        Hero,
        Subscriptions,
        Fun
    }
  }
  </script>
  