<template>
  <nav class="navbar py-4 fixed-top navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <router-link to="/">
        <img style="width: 130px" src="assets/img/logo.png" alt="Logo" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/" :class="{active}" class="nav-link">{{
              $t("menu.home")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/About" class="nav-link" href="#">{{
              $t("menu.about")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/Subscriptions" class="nav-link">{{
              $t("menu.subsciptions")
            }}</router-link>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ $t("menu.services") }}
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link to="/services/1" class="dropdown-item" href="#"
                  >{{$t("menu.service_1")}}</router-link
                >
              </li>
              <li>
                <router-link to="/services/2" class="dropdown-item" href="#"
                  >{{$t("menu.service_2")}}</router-link
                >
              </li>
              <li>
                <router-link to="/services/3" class="dropdown-item" href="#"
                  >{{$t("menu.service_3")}}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <div class="d-flex d-sm-none cs-main_header_right">
              <div class="cs-toolbox">
                <span
                  class="cs-btn cs-color1"
                  data-bs-toggle="modal"
                  data-bs-target="#register"
                  ><span>Start For Free</span></span
                >
              </div>

              <div class="dropdown">
                <button
                  class="dropdown-button dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="bx bx-world"></span>
                </button>
                <ul style="list-style: none" class="dropdown-menu">
                  <li @click="changeLang('ar')">
                    <a class="dropdown-item">
                      <div class="d-flex">
                        <flag iso="sa" height="20" class="me-2" />
                        <span class="align-middle">العربية</span>
                      </div>
                    </a>
                  </li>
                  <li @click="changeLang('en')">
                    <a class="dropdown-item">
                      <div class="d-flex">
                        <flag iso="us" height="20" class="me-2" />
                        <span class="align-middle">English</span>
                      </div></a
                    >
                  </li>
                </ul>
              </div>

              <div></div>
            </div>
          </li>
        </ul>
        <div class="d-none d-sm-flex align-items-center cs-main_header_right">
          <div class="cs-toolbox">
            <span
              class="cs-btn cs-color1"
              data-bs-toggle="modal"
              data-bs-target="#register"
              ><span>{{ $t("menu.button_text") }}</span></span
            >
          </div>

          <div class="dropdown">
            <button
              class="dropdown-button dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="bx bx-world"></span>
            </button>
            <ul style="list-style: none" class="dropdown-menu">
              <li @click="changeLang('ar')">
                <a class="dropdown-item">
                  <div class="d-flex">
                    <flag iso="sa" height="20" class="me-2" />
                    <span class="align-middle">العربية</span>
                  </div>
                </a>
              </li>
              <li @click="changeLang('en')">
                <a class="dropdown-item">
                  <div class="d-flex">
                    <flag iso="us" height="20" class="me-2" />
                    <span class="align-middle">English</span>
                  </div></a
                >
              </li>
            </ul>
          </div>

          <div></div>
        </div>
      </div>
    </div>

    <login-modal />
    <register-modal />
    <forget-password />
    <verfiy-password />
    <new-password />
  </nav>

  <!-- Start Header Section -->
  <!-- <header
    class="cs-site_header cs-style1 cs-sticky-header cs-primary_color cs-white_bg"
  >
    <div class="cs-main_header">
      <div class="container">
        <div class="cs-main_header_in">
          <div class="cs-main_header_left">
            <router-link to="/">
              <img style="width: 130px" src="assets/img/logo.png" alt="Logo" />
            </router-link>
          </div>
          <div class="cs-main_header_center">
            <div class="cs-nav">
              <ul class="cs-nav_list">
                <li>
                  <router-link to="/">{{ $t("menu.home") }}</router-link>
                </li>
                <li>
                  <router-link to="/About">{{ $t("menu.about") }}</router-link>
                </li>
                <li>
                  <router-link to="/Subscriptions">{{
                    $t("menu.subsciptions")
                  }}</router-link>
                </li>
                <li>
                  <label class="dropdown">
                    <div class="dd-button">Dropdown</div>

                    <input type="checkbox" class="dd-input" id="test" />

                    <ul class="dd-menu">
                      <li>Action</li>
                      <li>Another action</li>
                      <li>Something else here</li>
                      <li class="divider"></li>
                      <li>
                        <a href="http://rane.io">Link to Rane.io</a>
                      </li>
                    </ul>
                  </label>
                </li>
                <li>
                  <router-link to="/Contact">{{
                    $t("menu.contact")
                  }}</router-link>
                </li>
                <li>
                  <div class="d-flex d-sm-none cs-main_header_right">
                    <div class="cs-toolbox">
                      <span
                        class="cs-btn cs-color1"
                        data-bs-toggle="modal"
                        data-bs-target="#register"
                        ><span>Start For Free</span></span
                      >
                    </div>

                    <div class="dropdown">
                      <button
                        class="dropdown-button dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span class="bx bx-world"></span>
                      </button>
                      <ul style="list-style: none" class="dropdown-menu">
                        <li @click="changeLang('ar')">
                          <a class="dropdown-item">
                            <div class="d-flex">
                              <flag iso="sa" height="20" class="me-2" />
                              <span class="align-middle">العربية</span>
                            </div>
                          </a>
                        </li>
                        <li @click="changeLang('en')">
                          <a class="dropdown-item">
                            <div class="d-flex">
                              <flag iso="us" height="20" class="me-2" />
                              <span class="align-middle">English</span>
                            </div></a
                          >
                        </li>
                      </ul>
                    </div>

                    <div></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="d-none d-sm-flex cs-main_header_right">
            <div class="cs-toolbox">
              <span
                class="cs-btn cs-color1"
                data-bs-toggle="modal"
                data-bs-target="#register"
                ><span>Start For Free</span></span
              >
            </div>

            <div class="dropdown">
              <button
                class="dropdown-button dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="bx bx-world"></span>
              </button>
              <ul style="list-style: none" class="dropdown-menu">
                <li @click="changeLang('ar')">
                  <a class="dropdown-item">
                    <div class="d-flex">
                      <flag iso="sa" height="20" class="me-2" />
                      <span class="align-middle">العربية</span>
                    </div>
                  </a>
                </li>
                <li @click="changeLang('en')">
                  <a class="dropdown-item">
                    <div class="d-flex">
                      <flag iso="us" height="20" class="me-2" />
                      <span class="align-middle">English</span>
                    </div></a
                  >
                </li>
              </ul>
            </div>

            <div></div>
          </div>
          <login-modal />
          <register-modal />
          <forget-password />
          <verfiy-password />
          <new-password />
        </div>
      </div>
    </div>
  </header> -->

  <!-- End Header Section -->
</template>

<script>
import ForgetPassword from "../Modals/ForgetPassword.vue";
import LoginModal from "../Modals/LoginModal.vue";
import NewPassword from "../Modals/NewPassword.vue";
import RegisterModal from "../Modals/RegisterModal.vue";
import VerfiyPassword from "../Modals/VerfiyPassword.vue";
export default {
  components: {
    LoginModal,
    RegisterModal,
    ForgetPassword,
    VerfiyPassword,
    NewPassword,
  },
  data() {
    return {
      current_lang: "ar",
    };
  },
  methods: {
    changeLang(lang) {
      this.current_lang = lang;
      console.log(this.current_lang);
      this.$i18n.locale = this.current_lang;
      localStorage.setItem("pos_lang", this.current_lang);
      let body = document.querySelector("body");
      if (this.$i18n.locale == "ar") {
        body.classList.add("ar");
        body.classList.remove("en");
      } else {
        body.classList.add("en");
        body.classList.remove("ar");
      }
    },
  },
  created() {
    this.current_lang = localStorage.getItem("pos_lang") || "ar";
  },
};
</script>

<style></style>
