<template>
    <!-- Start Footer -->
    <footer class="cs-footer">
        <div class="cs-height_75 cs-height_lg_70"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="cs-footer_item">
                <div class="cs-footer_widget_text">
                  <img src="../../assets/img/logo_footer.png" style="width : 175px;" alt="Logo">
                  <p>
                   {{ $t("footer.descr") }}
                  </p>
                </div>
                <div class="cs-height_30 cs-height_lg_30"></div>
                <div class="cs-social_btns cs-style1">
                  <a href="#"><img src="../../assets/img/facebook.svg" alt="Facebook"></a>
                  <a href="#"><img src="../../assets/img/twitter.svg" alt="Twitter"></a>
                  <a href="#"><img src="../../assets/img/instagram.svg" alt="Instagram"></a>
                </div>
              </div>
            </div><!-- .col -->
            <div class="col-lg-3 col-md-6">
              <div class="cs-footer_item widget_nav_menu">
                <h2 class="cs-widget_title">{{$t("footer.services.title")}}</h2>
                <ul class="menu">
                  <li><router-link to="/services/1">{{$t("menu.service_1")}}</router-link></li>
                  <li><router-link to="/services/2">{{$t("menu.service_2")}}</router-link></li>
                  <li><router-link to="/services/3">{{$t("menu.service_3")}}</router-link></li>
                </ul>
              </div>
            </div><!-- .col -->
            <div class="col-lg-3 col-md-6">
              <div class="cs-footer_item widget_nav_menu">
                <h2 class="cs-widget_title">{{$t("footer.quick_links")}}</h2>
                <ul class="menu">
                  <li><router-link to="/">{{$t("menu.home")}}</router-link></li>
                  <li><router-link to="/About">{{$t("menu.about")}}</router-link></li>
                  <li><router-link to="/Contact">{{$t("menu.contact")}}</router-link></li>
                  <li><router-link to="/Subscriptions">{{$t("menu.subsciptions")}}</router-link></li>
                </ul>
              </div>
            </div><!-- .col -->
            <div class="col-lg-3 col-md-6">
              <div class="cs-footer_item widget_nav_menu">
                <h2 class="cs-widget_title">{{$t("footer.subscriptions.title")}}</h2>
                <form class="cs-newsletter">
                  <div class="cs-newsletter_text">{{$t("footer.subscriptions.descr")}}</div>
                  <div class="cs-height_20 cs-height_lg_20"></div>
                  <input type="text" class="cs-form_field" :placeholder='$t("footer.subscriptions.email")'>
                  <div class="cs-height_10 cs-height_lg_10"></div>
                  <button class="cs-btn cs-size_md w-100"><span>{{$t("footer.subscriptions.button_text")}}</span></button>
                </form>
              </div>
            </div><!-- .col -->
          </div>
        </div>
        <div class="cs-height_40 cs-height_lg_30"></div>
        <div class="cs-copyright text-center">
          <div class="container">Copyright 2023. Smart pos</div>
        </div>
      </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>