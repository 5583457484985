<template>
  <layout >
    <about />
  </layout>
</template>

<script>
import Layout from '@/components/mainLayout/Layout.vue'
import About from './About.vue'
export default {
  components: { Layout, About },

}
</script>

<style>

</style>