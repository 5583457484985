<template>
    <div
      class="modal modal-fullscreen fade"
      style="z-index: 50"
      id="newPass"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="z-index: 50">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close-login"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="cs-login">
              <div class="cs-login_left">
                <img src="assets/img/about_img_1.png" alt="Login Thumb" />
              </div>
              <div class="cs-login_right">
                <form class="cs-login_form" @submit.prevent="resetPassword()">
                  <h2>{{ $t("login.new_password.title") }}</h2>
                  <div class="cs-height_30 cs-height_lg_30"></div>
                  <input
                    v-model="pass"
                    type="password"
                    class="cs-form_field cs-border_color"
                    :placeholder="$t('login.new_password.new_password')"
                  />
                  <div class="cs-height_30 cs-height_lg_30"></div>
                  <input
                    v-model="re_pass"
                    type="password"
                    class="cs-form_field cs-border_color"
                    :placeholder="$t('login.new_password.re_password')"
                  />
                  <div class="cs-height_20 cs-height_lg_20"></div>
              
                  <div class="cs-height_20 cs-height_lg_20"></div>
                  <button class="cs-btn cs-size_md w-100">
                    <span>{{ $t("login.new_password.button_text") }}</span>
                  </button>
              <span class="login_btn"  data-bs-toggle="modal" data-bs-target="#login" style="display : none">{{$t("login.forget_section.button_text")}}</span>
                  

                 
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        pass : "",
        re_pass    :""
    };
    },
    methods: {
        async resetPassword(){
            if(this.pass == ""){
              this.$swal.fire({
        title: `error`,
        text: ` Please Add password `,
        icon: `error`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
          return
            }
            if(this.re_pass == ""){
              this.$swal.fire({
        title: `error`,
        text: ` Please Add password `,
        icon: `error`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
          return
            }
            if(this.pass != this.re_pass){
              this.$swal.fire({
        title: `error`,
        text: ` Passwords does not match `,
        icon: `error`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });rn
            }
            const data = {
                pass : this.pass,
                email : localStorage.getItem("resetEmail")
            }
            const res = await this.http.post("users/forget-password/reset-password" , data)
            if(!res?.status) {
          console.log("error")
          this.$swal.fire({
        title: `error`,
        text: ` Somthing went wrong `,
        icon: `error`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
        }else{
          this.$swal.fire({
        title: `success`,
        text:  "Successfully Reset Password now you can login to your account",
        icon: `success`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
           
          let btn =  document.querySelector(".login_btn")
          btn.click()
   
        }
        }
    },
  };
  </script>
  
  <style></style>
  