<template>
<layout >
   <!-- Start About -->
   <section id="about" class="cs-gradient_bg_1">
    <div class="cs-height_100 cs-height_lg_70"></div>
    <div class="container">
      <div class="row align-items-center flex-column-reverse-lg">
        <div class="col-xl-6 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
          <div class="cs-left_full_width cs-space110">
            <div class="cs-left_sided_img">
              <img src="https://www.retailtechinc.com/wp-content/uploads/2016/07/pexels-imin-technology-12935041-Large.jpeg" alt="About">
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="cs-height_0 cs-height_lg_40"></div>
          <div class="cs-seciton_heading cs-style1">
            <div class="cs-section_subtitle">{{$i18n.locale == 'ar' ? subscription?.package_type?.name_ar:subscription?.package_type?.name}}</div>
            <div class="cs-height_10 cs-height_lg_10"></div>
            <h3 class="cs-section_title">{{subscription?.name}} </h3>
          </div>
          <div class="cs-height_20 cs-height_lg_20"></div>
          <p>
            {{subscription?.descr}}
          </p>
          <div class="cs-height_15 cs-height_lg_15"></div>
          <div class="cs-list_1_wrap">
            <ul class="cs-list cs-style1 cs-mp0">
              <li>
                <span class="cs-list_icon">
                  <img src="assets/img/icons/tick.svg" alt="Tick">                      
                </span>
                <div class="cs-list_right">
                  <h3>Other point of sale information</h3>
                  <p>But I must explain to you how all this mistaken in denouncing pleasure and praising pain was born and I will give.</p>
                </div>
              </li>
              <li>
                <span class="cs-list_icon">
                  <img src="assets/img/icons/tick.svg" alt="Tick">                      
                </span>
                <div class="cs-list_right">
                  <h3>Best process system POS</h3>
                  <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti at.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="cs-height_100 cs-height_lg_70"></div>
    <div class="cs-height_135 cs-height_lg_0"></div>
  </section>
  <!-- End About -->

        
</layout>
</template>

<script>
import Layout from '@/components/mainLayout/Layout.vue'
export default {
  components: { Layout },
  data(){
  return {
    subscription : ""
  }
},
methods :{
  async getPackages(){
    const id = this.$route.params.id
    const res = await this.http.get(`subscription-packages/${id}`)
    this.subscription = res.data
  }
},
created(){
  this.getPackages()
}

}
</script>

<style>

</style>