<template>
  <layout >
    <Services />
  </layout>
</template>

<script>
import Layout from '@/components/mainLayout/Layout.vue'
import Services from './Services.vue'
export default {
  components: { Layout, Services },

}
</script>

<style>

</style>