<template>

    <!-- Modal -->
<div class="modal modal-fullscreen fade" style="z-index:50" id="verfiy" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl" style="z-index:50">
    <div class="modal-content" >
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="cs-login">
          <div class="cs-login_left">
            <img src="assets/img/about_img_1.png" alt="Login Thumb">
          </div>
          <div class="cs-login_right">
            <form class="cs-login_form" @submit.prevent="newPassword()">
              <h2>{{$t("login.verfiy.title")}}</h2>
              <div class="cs-height_30 cs-height_lg_30"></div>
              <input v-model="code" type="text" class="cs-form_field cs-border_color" :placeholder="$t('login.verfiy.code')">
              <div class="cs-height_20 cs-height_lg_20"></div>
              <button class="cs-btn cs-size_md w-100"><span>{{$t("login.verfiy.button_text")}}</span></button>
              <span class="new_btn"  data-bs-toggle="modal" data-bs-target="#newPass" style="display : none">{{$t("login.forget_section.button_text")}}</span>

              <div class="cs-height_20 cs-height_lg_20"></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
    data(){
        return {
            code : "",
        }
    },
    methods:{
    newPassword(){
      

        if(this.code == ""){
          this.$swal.fire({
        title: `error`,
        text: ` Please Add a code `,
        icon: `error`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
          return;
      }else{
        const data = {
            code : this.code,
            email : localStorage.getItem("resetEmail")
        }
         this.http.post("users/forget-password/verfiy-code" , data).then((res)=>{
          if(res?.status) {
          let btn =  document.querySelector(".new_btn")
      btn.click()
        }else{
          console.log("error")  
          this.$swal.fire({
        title: `error`,
        text: ` Wrong code check it again `,
        icon: `error`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
        }
         }).catch((err)=>{  
          this.$swal.fire({
        title: `error`,
        text: ` Network error `,
        icon: `error`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
         })
        
      }
       let btn =  document.querySelector(".new_btn")
      btn.click()
    }
  }
}
</script>

<style>

</style>